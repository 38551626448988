:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--introMobileImg: url(RESOURCE/img/banner.Xc_770_770.jpg);
	--navbarbreak: 768px;
	--Primarycolor: #2d5f91;
	--Secondarycolor: #f5c37d;
	--Themelight: #2d5f91;
	--Reetlandred: #ff0a4b;
	--Darkgreen: #333;
	--Lightgreen: #f0faef;
	--cal_not_available: #b32e31;
	--cal_free: #ccaf6e;
	--white: #fff;
	--menuitem: #2d5f91;
	--black: #000;

	/*Calendar colors*/
	--not_available: #b32e31;
	--disabled-alt: #d0e9c7;
	--disabled: #387222;
	--available: #387222;
	--selected: #062e5f;
	--selection_range: #4D6E96;
	--selected_border: #fff;
	--text_color: #000;
}