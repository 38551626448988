.vo-page-body {
	.navbarheader {
		position: fixed;
		top: 0;
		margin: 0;
		background: var(--Primarycolor);
		width: 100%;
	}
}


.payment-view {
	padding-top: 130px;
	padding-bottom: 50px;

	h1 {
		font-size: 2.2rem;
		line-height: 1.2;
		margin: 20px 0 30px;
		width: 100%;
		padding: 0;
		font-family: Barlow Condensed, sans-serif;
		color: var(--Themelight);
		font-weight: 600;
		letter-spacing: .2rem;
		text-transform: uppercase;
	}

	.btn-primary.active,
	.btn-primary:active,
	.open>.dropdown-toggle.btn-primary {
		border: 1px solid var(--Primarycolor) !important;
		background: var(--Primarycolor);
		box-shadow: none !important;
		padding-top: 10px;
		padding-bottom: 10px;
		font-size: 18px;

		&:hover {
			background: transparent;
			color: var(--Themelight) !important;
		}
	}
}

.map_img {
	position: relative;

	.map-icons {
		position: absolute;
		bottom: 0px;
		left: 60px;
		display: flex;

		.icon {
			background: var(--Reetlandred);
			color: var(--white);
			width: 40px;
			height: 40px;
			font-size: 18px;
			display: flex;
			align-items: center;
			margin-right: 5px;
			justify-content: center;

			&:hover {
				background: var(--Primarycolor);
			}
		}
	}
}

.ch-title {
	font-family: EB Garamond, serif;
	font-size: 1.8rem;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 0.2rem;
	-ms-hyphens: none;
	-webkit-hyphens: none;
	-o-hyphens: none;
	hyphens: none;
	margin-top: 20px;
	text-align: center;
	color: var(--Darkgreen);
}

.image-circle {
	margin-bottom: 15px;
	width: 100%;
	padding: 25px;
}

.static-view {
	padding: 0 0;

	.container {
		width: 100%;
		max-width: 1700px;
	}

	.container-default {
		width: 100%;
		max-width: 1170px;
		margin: 0 auto;
	}

	.pt-50 {
		padding-top: 50px !important;
	}

	.mt-100 {
		margin-top: 100px;
	}

	.mb-30 {
		margin-bottom: 30px;
	}

	h2 {
		font-size: 2.2rem;
		line-height: 33px;
		font-weight: 400;
		color: #424242;
		width: 100%;
	}


	.static-banner {
		margin: 100px 0 0 0;
		position: relative;

		img {
			width: 100%;
			height: 400px;
			object-fit: cover;
		}

		h2 {
			position: absolute;
			left: 0;
			top: 0;
			color: #fff;
			right: 0;
			bottom: 0;
			margin: 0 auto;
			text-align: center;
			z-index: 1;
			letter-spacing: 0.2rem;
			text-shadow: rgba(0, 0, 0, .4) 0 -1px 0;
			font-size: 3rem;
			text-transform: uppercase;
			line-height: 47px;
			display: flex;
			-webkit-box-align: center;
			align-items: center;
			-webkit-box-pack: center;
			justify-content: center;
			word-break: break-word;
		}
	}

	.container-small {
		width: 100%;
		margin: 0 auto;
		max-width: 1035px;
		padding: 0 15px;
		display: flex;
		flex-wrap: wrap;

		h2 {
			text-align: center;
			font-size: 2.2rem;
			line-height: 1.2;
			margin: 20px 0 30px;
			width: 100%;
			padding: 0;
			font-family: Barlow Condensed, sans-serif;
			color: var(--Themelight);
			font-weight: 600;
			letter-spacing: .2rem;
			text-transform: uppercase;
		}

		h3 {
			margin: 0 0 10px;
			font-size: 1.25rem;
			line-height: 28px;
			font-family: EB Garamond, serif;
			font-weight: 400;
			color: var(--Reetlandred);
			text-align: center;
			width: 100%;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			&:before {
				display: block;
				content: "\A0\2014\A0";
				font-size: 1.875rem;
				font-weight: 100;
				margin: 0 15px;
				line-height: 20px;
				font-family: Barlow Condensed, sans-serif;
			}

			&:after {
				display: block;
				content: "\A0\2014\A0";
				font-size: 1.875rem;
				font-weight: 100;
				margin: 0 15px;
				line-height: 20px;
				font-family: Barlow Condensed, sans-serif;
			}
		}

		h4 {
			color: var(--Primarycolor);
			letter-spacing: 0px;
			transition: c;
			text-align: center;
			margin: 0 0 15px;
			width: 100%;
		}

		p {
			text-align: center;
			font-size: 18px;
			line-height: 28px;
			word-spacing: 0.25em;
			font-weight: 400;
			margin: 0 0 20px;
			width: 100%;
			color: var(--Darkgreen);

			em {
				margin-top: 15px;
				display: flex;
			}

			.btn-primary {
				text-align: center;
				background-color: #fff;
				border: 1px solid var(--Themelight);
				border-radius: 2px;
				line-height: 38px;
				color: var(--Themelight) !important;
				padding: 8px 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 48px;
				font-size: 18px;
				letter-spacing: 0.05rem;
				max-width: 250px;
				margin: 0 auto;

				&:hover {
					color: var(--Reetlandred) !important;
					border: 1px solid var(--Reetlandred) !important;
				}
			}
		}

		h6 {
			margin: 0 0 10px;
			font-size: 18px;
			line-height: 24px;
			font-family: EB Garamond, serif;
			font-weight: 400;
			color: var(--Reetlandred);
			text-align: left;
			width: 100%;
		}
	}

	.container-big {
		max-width: 1700px;
	}

	.immo_img_area {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
		margin: 10px 0 40px 0;

		.box {
			width: 33.33%;
			padding: 0 15px;

			img {
				width: 100%;
			}
		}
	}

	.divider {
		width: 100%;
		height: 1px;
		position: relative;
		clear: both;
		margin: 40px 0;
		background-color: var(--Primarycolor);

		&:after {
			width: 50px;
			height: 50px;
			top: -24px;
			background-size: 30px;
			background-position: center;
			position: absolute;
			display: block;
			padding: 10px;
			background-color: #fff;
			background-image: url(RESOURCE/img/usedomtravel-logo-white-1.png);
			background-repeat: no-repeat;
			content: "";
			left: 50%;
			transform: translate(-50%);
		}
	}

	.divider.abstand {
		margin-top: 70px;
		margin-bottom: 70px;
	}

	.grid-box-section {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		align-items: stretch;

		img {
			height: 335px;
			object-fit: cover;
			width: 100%;
			min-height: 100%;
		}

		.home_grid_50 {
			width: 49%;
			margin-bottom: 1%;
		}

		.home_grid_25 {
			width: 24%;
			margin-bottom: 1%;
		}

		.text {
			padding: 20px;
			background-color: #f1f1f1;

			h3 {
				margin: 0 0 15px;
				font-size: 20px;
				line-height: 24px;
				color: var(--Primarycolor);
				letter-spacing: 0px;
			}

			p {
				font-size: 14px;
				line-height: 22px;
				font-weight: 400;
			}
		}
	}

	.immo_grid_area {
		display: flex;
		align-items: stretch;
		justify-content: center;
		margin: 0 0 50px 0;

		.immo_grid {
			width: 25%;
			margin-right: 5%;
			padding: 20px;
			text-align: center;
			background-color: #efefef;

			svg {
				margin-bottom: 20px;
				width: 50px;
				height: 57px;
			}

			h4 {
				margin: 0 0 10px 0;
				font-size: 26px;
			}

			p {
				text-align: center;
				font-size: 13px;
				line-height: 22px;
				margin: 0 0 30px 0;
			}
		}
	}

	.immo_quote {
		margin-top: 100px;
		margin-bottom: 100px;
		text-align: center;
		font-size: 31px;
		font-weight: bold;
		width: 100%;

		span {
			color: var(--Primarycolor);
		}
	}

	.ct-form-section {
		margin: 00;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		padding: 50px 0 50px 0;

		h2 {
			font-size: 30px;
			margin: 0 0 10px;
			width: 100%;
			padding: 0;
			font-family: Barlow Condensed, sans-serif;
			color: var(--Themelight);
			font-weight: 600;
			letter-spacing: .2rem;
			text-transform: uppercase;
		}

		.left {
			h4 {
				font-size: 20px;
				margin: 0 0 20px 0;
				font-weight: 400;
				color: var(--Darkgreen);
				width: 100%;
				line-height: 32px;
				font-family: 'EB Garamond', serif;
			}

			.control-label {
				text-align: left;
				font-size: 18px;
				line-height: 24px;
				color: var(--Darkgreen);
				margin: 0 0 5px 0;
				font-family: 'EB Garamond', serif;
			}

			.form-control {
				height: 40px;
				border-radius: 0;
			}

			textarea {
				height: 75px !important;
			}

			.btn-go {
				text-align: center;
				background-color: #fff;
				border: 1px solid var(--Themelight);
				border-radius: 2px;
				line-height: 38px;
				color: var(--Themelight) !important;
				padding: 8px 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 48px;
				font-size: 18px;
				letter-spacing: 0.05rem;

				&:hover {
					color: var(--Reetlandred) !important;
					border: 1px solid var(--Reetlandred);
				}
			}

			.checkbox {
				font-size: 18px;
				font-weight: 400;
				line-height: 18px;
				color: var(--Darkgreen);

				.privacy-link {
					color: var(--Primarycolor);
				}
			}
		}

		.contact-form {
			.text-right {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}

		.contact-section-side {
			background-color: #f5f5f5;
			padding: 15px;
			margin-bottom: 30px;
		}

		.right {
			h3 {

				margin: 0 0 10px;
				width: 100%;
				padding: 0;
				font-family: Barlow Condensed, sans-serif;
				color: var(--Themelight);
				font-weight: 600;
				letter-spacing: .2rem;
				text-transform: uppercase;

				@media (min-width:1200px) {
					font-size: 30px;
				}
			}

			iframe {
				margin: 0 0 30px 0;
			}

			.list-icon {
				padding: 0;
				margin: 0;
				list-style: none;

				li {
					position: relative;
					margin: 0 0 10px 0;
					padding: 0 0 0 20px;
					font-size: 18px;
					line-height: 24px;

					.fa {
						position: absolute;
						left: 0;
						font-size: 15px;
						top: 6px;
					}

					a {
						color: var(--Primarycolor);
					}
				}
			}
		}
	}

	.usedom-banner {
		img {
			height: auto;
		}

		.searchbar {
			padding: 0;
			margin: 0;
			position: absolute;
			width: 100%;
			bottom: 50px;
		}

		.container {
			background: rgb(0 0 0/.4);
			padding: 15px;
			border-radius: 100px;
			width: 100%;
			max-width: 1170px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			color: #fff;

			.searchbox {
				padding: 5px;
			}

			input.form-control {
				border-radius: 100px;
				font-size: 15px;
				height: 45px;
			}

			.search-btn {
				background: var(--Primarycolor);
				color: #fff;
				box-shadow: none;
				border-radius: 100px;
				font-size: 15px;
				text-transform: uppercase;
				letter-spacing: .5px;
				font-weight: 600;
				border: 2px solid var(--Primarycolor);
				height: 45px;
				width: 100%;

				&:hover {
					background: transparent;
				}
			}
		}
	}

	.static-sidebar {
		.sidebar-links {
			ul {
				padding: 0;
				margin: 0;
				list-style: none;
				display: flex;
				width: 100%;
				flex-wrap: wrap;

				li {
					width: 100%;

					a {
						position: relative;
						display: block;
						margin-bottom: 5px;
						padding: 2px 10px 2px 50px;
						background-color: #EFEFEF;
						border-radius: 2px;
						line-height: 30px;
						text-decoration: none;
						color: #424242;
						box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 3px;
						font-weight: 300;

						&:hover {
							background-color: #424242;
							text-decoration: none;
							color: var(--Primarycolor);
						}

						.fas {
							width: 24px;
							height: 24px;
							left: 10px;
							top: 5px;
							position: absolute;
							text-align: center;
							background-color: var(--Primarycolor);
							font-size: 8px;
							color: #fff;
							z-index: 1;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
				}
			}
		}

		.sidebar-contact {
			margin: 30px 0 0 0;
			background-color: #f5f5f5;
			padding: 0 0 15px 0;

			h3 {
				margin: 0;
				padding: 1px 15px;
				background-color: #5F5F5F;
				color: #fff;
				text-transform: uppercase;
				letter-spacing: 1px;
			}

			h4 {
				margin: 10px 0;
				padding: 0 10px;

				img {
					margin: 0 auto;
					max-width: 100%;
				}
			}

			h5 {
				margin: 5px 0 0 0;
				padding-right: 15px;
				padding-left: 15px;
				font-size: 20px;
				font-weight: bold;
				letter-spacing: 1px;
				text-align: left;
			}

			p {
				margin: 5px 0 20px;
				text-align: left;
				font-size: 15px;
				line-height: 24px;
				font-weight: 400;
				padding: 0 15px;
				display: flex;
				align-items: center;

				.fas {
					color: var(--Primarycolor);
					margin: 0 10px 0 0;
					font-size: 14px;
				}

				a {
					color: #333;
				}
			}

			h6 {
				margin: 0;
				width: 100%;
				padding: 0 15px;

				a {
					position: relative;
					display: block;
					margin-bottom: 10px;
					padding-right: 20px;
					padding-left: 20px;
					text-align: center;
					background-color: #fff;
					border: 1px solid #424242;
					border-radius: 2px;
					font-size: 14px;
					line-height: 36px;
					text-decoration: none;
					color: #424242;
					text-transform: uppercase;
					letter-spacing: 1px;
					box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 3px;
					white-space: nowrap;
					display: flex;
					align-items: center;
					justify-content: space-around;

					&:hover {
						background-color: #424242;
						color: #fff;
					}
				}
			}
		}
	}

	.static-content-area {
		h2 {
			text-align: center;
			font-size: 2.2rem;
			line-height: 33px;
			margin: 0 0 10px;
			width: 100%;
			padding: 0;
			font-family: Barlow Condensed, sans-serif;
			color: var(--Themelight);
			font-weight: 600;
			letter-spacing: .2rem;
			text-transform: uppercase;
			word-break: break-word;
		}

		a {
			word-break: break-word;
		}

		p {
			line-height: 1.6rem;
		}

		h3 {
			font-family: 'Barlow Condensed', sans-serif;
			font-size: 1.25rem;
			font-weight: 400;
			color: var(--Themelight);
			-ms-hyphens: none;
			-webkit-hyphens: none;
			-o-hyphens: none;
			hyphens: none;
			margin-top: 20px;
		}
	}

	.static-right-content {
		h2 {
			text-align: center;
			font-size: 2.2rem;
			line-height: 33px;
			margin: 0 0 10px;
			width: 100%;
			padding: 0;
			font-family: Barlow Condensed, sans-serif;
			color: var(--Themelight);
			font-weight: 600;
			letter-spacing: .2rem;
			text-transform: uppercase;
		}

		h3 {
			margin: 0 0 30px;
			font-size: 20px;
			line-height: 28px;
			font-family: EB Garamond, serif;
			font-weight: 400;
			color: var(--Reetlandred);
			text-align: center;
		}

		.ce_accordion {
			margin: 0 0 5px 0 !important;
		}

		.panel-default>.panel-heading a:after {
			content: "";
			font-family: "Font Awesome 5 Free";
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			transition: transform .25s linear;
			-webkit-transition: -webkit-transform .25s linear;
			background-color: var(--Primarycolor);
			color: #fff;
			width: 25px;
			height: 25px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 25px;
			position: absolute;
			left: 15px;
			top: 13px;
		}

		.panel-default>.panel-heading {
			color: #333;
			border: none;
			position: relative;
			padding: 0;
			background-color: #f5f5f5;
			font-size: 20px;

			&:hover a:after {
				background-color: #424242;
			}
		}

		.panel-default>.panel-heading a[aria-expanded="true"] {}

		.panel-default>.panel-heading a {
			width: 100%;
			display: flex;
			padding: 10px 15px 10px 60px;
			color: var(--Darkgreen);
		}

		.panel-default>.panel-heading .panel-title {
			font-size: 22px;
			line-height: 30px;
		}

		.panel-default>.panel-heading a[aria-expanded="true"]:after {

			content: "\f068";
			/*			-webkit-transform: rotate(225deg);
			transform: rotate(225deg);*/
			font-family: "FontAwesome";
			font-size: 12px;
		}

		.panel-default>.panel-heading a[aria-expanded="false"]:after {
			content: "\f067";
			/*-webkit-transform: rotate(90deg);
			transform: rotate(90deg);*/
			font-family: "FontAwesome";
			font-size: 12px;

		}

		.panel-body {
			margin: 0 0 20px;
			text-align: left;
			font-size: 18px;
			line-height: 24px;
			font-weight: 400;
			border: none !important;
			box-shadow: none;
			color: var(--Darkgreen);
			font-family: EB Garamond, serif;
		}

		.panel-group .panel {
			border-radius: 0;
			border: none;
		}

		p {
			margin: 0 0 20px;
			text-align: left;
			font-size: 15px;
			line-height: 24px;
			font-weight: 400;
		}
	}

	.faq-content {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 50px 0;
	}

	.impressum-content {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		width: 100%;
		padding: 50px 0;

		p {
			a {
				color: var(--Primarycolor);
			}
		}
	}

	.footer_kon_box {
		display: flex;
		flex-wrap: wrap;
		width: 100%;

		.top_kon_box {
			background-color: var(--Primarycolor);
			color: #fff;
			width: 100%;
			text-align: center;
			padding: 40px 20px;

			h4 {
				font-size: 36px;
				padding: 0;
				margin: 0;
				text-transform: uppercase;
			}
		}

		.bottom_kon_box {
			width: 100%;
			/*padding: 100px 18px 240px;*/
			padding: 50px 18px 50px;

			.left {
				padding: 0 60px 0 0;
				position: relative;

				.adress_box {
					position: absolute;
					padding: 40px;
					background-color: rgba(66, 66, 66, .8);
					color: #fff;
					z-index: 1;
					bottom: -130px;
					left: 0;
					right: 0;
					max-width: 430px;
					margin: 0 auto;

					p {
						margin: 0 0 20px;
						text-align: left;
						font-size: 16px;
						line-height: 24px;
						font-weight: 400;
						font-weight: 400;
						font-family: EB Garamond, serif;

						strong {
							font-weight: 500;
							letter-spacing: 0.25px;
							font-size: 22px;
						}

						a {
							color: #fff;
						}
					}
				}
			}

			.right {
				padding: 0 0px 0 60px;

				.form-section {
					padding: 20px;
					background-color: #f5f5f5;
					display: flex;
					flex-wrap: wrap;
					width: 100%;

					.contact-page {
						.row {
							display: flex;
							flex-wrap: wrap;

							.control-label {
								display: none;
							}

							.form-control {
								height: 40px;
								border-radius: 0;
								font-size: 18px;
							}

							.msg-control {
								height: 100px;
								padding: 15px;
								background: #fff;
							}

							.check {
								margin: 0;

								a {
									color: var(--Primarycolor) !important;
								}

								label {
									font-size: 18px;
									color: var(--Darkgreen);
									line-height: 20px;
									font-family: EB Garamond, serif;
								}
							}

							.submit-area {
								margin: 0;

								.btn-go {
									text-align: center;
									background-color: #fff;
									border: 1px solid var(--Themelight);
									border-radius: 2px;
									line-height: 38px;
									color: var(--Themelight) !important;
									padding: 8px 20px;
									display: flex;
									align-items: center;
									justify-content: center;
									height: 48px;
									font-size: 18px;
									letter-spacing: 0.05rem;
									margin: 20px 0 0 0;

									&:hover {
										color: var(--Reetlandred) !important;
										border: 1px solid var(--Reetlandred);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.map_img img {
	max-width: 100%;
}

.resort-main-content {
	.mt-30 {
		margin-top: 30px !important;
	}

	img {
		width: 100%;
	}

	.text-left {
		text-align: left !important;

		p {
			text-align: left !important;
		}
	}

	.text-right {
		text-align: right !important;

		p {
			text-align: right !important;
		}
	}

	.owl-carousel {
		.owl-item {
			img {
				height: 600px;
				object-fit: cover;
			}
		}
	}

	.owl-nav {
		button {
			outline: none;
			color: #fff !important;
		}
	}

	.container-small {
		iframe {
			width: 100%;
		}
	}
}

.contact-map {
	#map {
		min-height: 300px !important;
	}
}

.bg-li-blue {
	margin: 0 0 50px 0;

	.container {
		width: 100%;
		max-width: 1170px;

		h2 {
			font-size: 40px;
			font-weight: 400;
			color: #424242;
			width: 100%;
		}

		.team-bl {
			text-align: center;

			img {
				width: 300px;
				margin: 0 auto;
			}
		}
	}
}

.contact-bottom {
	.container-small {}

	.left {
		margin: 0 0 50px 0;

		h2 {
			text-align: left;
			margin: 0;

		}

		p {
			text-align: left;
			margin: 10px 0 15px 0;
		}
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			font-size: 18px;
			line-height: 24px;
			margin: 0 0 10px;
			position: relative;
			padding: 0 0 0 30px;
			color: var(--Darkgreen);

			&:before {
				position: absolute;
				left: 0;
				content: "—";
				display: block;
			}
		}
	}
}

.contact-bottom2 {
	.container-small {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.left {
			margin: 0 0 50px 0;

			p {
				text-align: left;
				margin: 0;
			}
		}
	}
}

@media (max-width: 767px) {
	.static-view {
		.static-banner {
			margin: 70px 0 0;
		}

		.immo_img_area {
			margin: 10px 0 25px;


			.box {
				padding: 0 5px;
			}
		}

		.grid-box-section {
			.home_grid_50 {
				width: 100%;
				margin-bottom: 5%;
			}

			.home_grid_25 {
				width: 100%;
				margin-bottom: 5%;
			}
		}

		.container-small {
			p {
				margin: 0 0 20px 0;
			}
		}

		.divider.abstand {
			margin-top: 40px;
			margin-bottom: 50px;
		}

		.immo_grid_area {
			flex-wrap: wrap;

			.immo_grid {
				width: 100%;
				margin-right: 0;
				margin-bottom: 2%;
			}
		}

		.immo_quote {
			margin-top: 40px;
			margin-bottom: 40px;
			font-size: 20px;
		}

		.mt-100 {
			margin-top: 40px;
		}

		.footer_kon_box {
			display: none !important;
		}
	}

	.static-sidebar {
		display: none !important;
	}

	.resort-main-content {
		.owl-carousel {
			.owl-item {
				img {
					height: 300px;
				}
			}
		}
	}
}

@media all and (min-width: 320px) and (max-width: 767px) {
	.static-view {

		.static-banner {
			h2 {
				font-size: 30px;
				line-height: 34px;
			}

			img {
				height: 180px;
			}
		}
	}

	.usedom-banner {
		.searchbar {
			display: none;
		}
	}

	.container-small {
		h2 {
			font-size: 30px !important;
		}
	}

}


@media all and (min-width: 768px) and (max-width: 991px) {
	.static-view {
		.static-banner {
			h2 {
				font-size: 40px;
			}
		}

		.grid-box-section {
			.home_grid_50 {
				width: 100%;
			}

			.home_grid_25 {
				width: 100%;
			}
		}

		.immo_grid_area {
			flex-wrap: wrap;

			.immo_grid {
				width: 100%;
				margin-right: 0;
				margin-bottom: 5%;
			}
		}

		.immo_quote {
			margin-top: 30px;
			margin-bottom: 50px;
			font-size: 20px;
		}

		.footer_kon_box {
			.top_kon_box {
				h4 {
					font-size: 26px;
				}
			}

			.bottom_kon_box {
				padding: 100px 18px 100px;

				.left {
					padding: 0 0 0 20px;

					.adress_box {
						max-width: 250px;
						padding: 20px;
					}
				}

				.right {
					padding: 0 0 0 20px;
				}
			}
		}
	}

	.resort-main-content {
		.owl-carousel {
			.owl-item {
				img {
					height: 400px;
				}
			}
		}
	}
}


@media all and (min-width: 992px) and (max-width: 1199px) {
	.static-view {
		.immo_grid_area {
			.immo_grid {
				margin: 0 30px;
			}
		}

		.footer_kon_box {
			.bottom_kon_box {
				.left {
					padding: 0 20px 0 0;

					.adress_box {
						max-width: 310px;
					}
				}

				.right {
					padding: 0 0 0 20px;
				}
			}
		}
	}
}